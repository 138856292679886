import { useEffect, useState } from "react";
import styles from "./ReviewManagement.module.css"; // We'll create this CSS file next
import { useAdmin } from "hooks/useAdmin";
import {
  BsThreeDotsVertical,
  BsExclamationTriangle,
  BsSearch,
  BsFilter,
} from "react-icons/bs";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Button from "components/Button/Button";
import { useToastContext } from "contexts/ToastContext";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import { formatDate } from "utils/dates";
import { useNavigate } from "react-router-dom";
import ReviewActionModal from "../components/ReviewActionModal";

// Status options for filtering
const STATUS_OPTIONS = [
  { value: "", label: "All Statuses" },
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
  { value: "rejected", label: "Rejected" },
];

// Interaction type options for filtering
const INTERACTION_TYPE_OPTIONS = [
  { value: "", label: "All Types" },
  { value: "Chat", label: "Chat" },
  { value: "Match", label: "Match" },
  { value: "Reachout", label: "Reachout" },
];

// Helper function to format interaction type
const formatInteractionType = (type) => {
  switch (type) {
    case "Chat":
      return "Chat";
    case "Match":
      return "Match";
    case "Reachout":
      return "Reachout";
    default:
      return type;
  }
};

const ReviewManagement = () => {
  const { addToast } = useToastContext();
  const {
    reviews,
    reviewsPagination,
    fetchAllReviews,
    approveReviewItem,
    rejectReviewItem,
    fetchReviewStats,
  } = useAdmin();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reviewStatsData, setReviewStatsData] = useState({
    total: 0,
    pending: 0,
    approved: 0,
    rejected: 0,
  });

  // Filtering and sorting states
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [interactionTypeFilter, setInteractionTypeFilter] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  // Add these states for review actions
  const [selectedReview, setSelectedReview] = useState(null);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Load reviews on initial render and when filters change
  useEffect(() => {
    loadReviews();
  }, [page, statusFilter, interactionTypeFilter, sortField, sortDirection]);

  // Load review stats on initial render
  useEffect(() => {
    loadReviewStats();
  }, []);

  const loadReviewStats = async () => {
    try {
      const response = await fetchReviewStats();
      if (response && response.success) {
        setReviewStatsData(response.stats);
      }
    } catch (error) {
      console.error("Error loading review stats:", error);
    }
  };

  const loadReviews = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await fetchAllReviews({
        page,
        status: statusFilter,
        interactionType: interactionTypeFilter,
        sort: `${sortDirection === "desc" ? "-" : ""}${sortField}`,
        search: searchTerm,
      });
    } catch (error) {
      console.error("Error loading reviews:", error);
      setError("Failed to load reviews. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page
    loadReviews();
  };

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("desc"); // Default to descending for new field
    }
  };

  const clearFilters = () => {
    setSearchTerm("");
    setStatusFilter("");
    setInteractionTypeFilter("");
    setSortField("createdAt");
    setSortDirection("desc");
    setPage(1);
  };

  // Handle row click to view review details
  const handleRowClick = (reviewId) => {
    navigate(`/admin/reviews/${reviewId}`);
  };

  // Handle review actions
  const handleApproveAction = (review) => {
    setSelectedReview(review);
    setIsApproveModalOpen(true);
  };

  const handleRejectAction = (review) => {
    setSelectedReview(review);
    setIsRejectModalOpen(true);
  };

  const handleApproveSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await approveReviewItem(selectedReview._id, data);
      addToast("Review approved successfully", "success");
      loadReviews();
      loadReviewStats();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
      setIsApproveModalOpen(false);
    }
  };

  const handleRejectSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await rejectReviewItem(selectedReview._id, data);
      addToast("Review rejected successfully", "success");
      loadReviews();
      loadReviewStats();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
      setIsRejectModalOpen(false);
    }
  };

  // Get review action options
  const getReviewActionOptions = (review) => {
    const options = [];

    options.push({
      label: "View Details",
      onClick: () => handleRowClick(review._id),
    });

    // Add approve/reject options based on review status
    if (review.status === "pending") {
      options.push({
        label: "Approve",
        onClick: () => handleApproveAction(review),
      });

      options.push({
        label: "Reject",
        onClick: () => handleRejectAction(review),
      });
    }

    return options;
  };

  return (
    <div className={styles.reviewManagement}>
      <div className={styles.header}>
        <h1>Review Management</h1>
        <div className={styles.stats}>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {reviewStatsData.pending || 0}
            </span>
            <span className={styles.statLabel}>Pending</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {reviewStatsData.approved || 0}
            </span>
            <span className={styles.statLabel}>Approved</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {reviewStatsData.rejected || 0}
            </span>
            <span className={styles.statLabel}>Rejected</span>
          </div>
        </div>
      </div>

      <div className={styles.filters}>
        <form className={styles.searchForm} onSubmit={handleSearch}>
          <div className={styles.searchInput}>
            <input
              type="text"
              placeholder="Search by username or content..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button type="submit">
              <BsSearch />
            </button>
          </div>
        </form>

        <div className={styles.filterControls}>
          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Status:
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                {STATUS_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Interaction Type:
              <select
                value={interactionTypeFilter}
                onChange={(e) => setInteractionTypeFilter(e.target.value)}
              >
                {INTERACTION_TYPE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <Button
            onClick={clearFilters}
            color="secondary"
            className={styles.clearButton}
          >
            Clear Filters
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : error ? (
        <div className={styles.errorContainer}>
          <BsExclamationTriangle size={48} />
          <p>{error}</p>
          <Button onClick={loadReviews}>Try Again</Button>
        </div>
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.reviewsTable}>
            <thead>
              <tr>
                <th
                  onClick={() => toggleSort("createdAt")}
                  className={styles.sortableHeader}
                >
                  Date
                  {sortField === "createdAt" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th>Interaction Type</th>
                <th>Author</th>
                <th>Subject</th>
                <th
                  onClick={() => toggleSort("trustScore")}
                  className={styles.sortableHeader}
                >
                  Trust Score
                  {sortField === "trustScore" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSort("warmthScore")}
                  className={styles.sortableHeader}
                >
                  Warmth Score
                  {sortField === "warmthScore" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSort("status")}
                  className={styles.sortableHeader}
                >
                  Status
                  {sortField === "status" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th className={styles.actionsHeader}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!reviews || reviews.length === 0 ? (
                <tr>
                  <td colSpan="8" className={styles.noResults}>
                    No reviews found matching your criteria
                  </td>
                </tr>
              ) : (
                reviews.map((review) => (
                  <tr
                    key={review._id}
                    className={styles.reviewRow}
                    onClick={() => handleRowClick(review._id)}
                  >
                    <td className={styles.reviewDataCell}>
                      {formatDate(review.createdAt)}
                    </td>
                    <td className={styles.reviewDataCell}>
                      {formatInteractionType(review.interactionType)}
                    </td>
                    <td className={styles.reviewDataCell}>
                      {review.author?.username || "Unknown"}
                    </td>
                    <td className={styles.reviewDataCell}>
                      {review.subject?.username || "Unknown"}
                    </td>
                    <td className={styles.reviewDataCell}>
                      {review.trustScore}
                    </td>
                    <td className={styles.reviewDataCell}>
                      {review.warmthScore}
                    </td>
                    <td className={styles.reviewDataCell}>
                      <span className={styles[`status-${review.status}`]}>
                        {review.status}
                      </span>
                    </td>
                    <td
                      className={styles.actionCell}
                      onClick={(e) => e.stopPropagation()} // Prevent row click when clicking on actions
                    >
                      <ReusableActionMenu
                        options={getReviewActionOptions(review)}
                        icon={BsThreeDotsVertical}
                        menuHeader="Review Actions"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination controls */}
      {reviews && reviews.length > 0 && (
        <div className={styles.pagination}>
          <Button
            onClick={() => setPage(Math.max(1, page - 1))}
            disabled={page === 1 || isLoading}
          >
            Previous
          </Button>
          <span>
            Page {page} of {reviewsPagination?.pages || 1}
          </span>
          <Button
            onClick={() => setPage(page + 1)}
            disabled={page >= (reviewsPagination?.pages || 1) || isLoading}
          >
            Next
          </Button>
        </div>
      )}

      {/* Modals for review actions */}
      <ReviewActionModal
        isOpen={isApproveModalOpen}
        onClose={() => setIsApproveModalOpen(false)}
        actionType="Approve"
        review={selectedReview}
        onSubmit={handleApproveSubmit}
        isSubmitting={isSubmitting}
      />

      <ReviewActionModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        actionType="Reject"
        review={selectedReview}
        onSubmit={handleRejectSubmit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ReviewManagement;
