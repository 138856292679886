import { useState } from "react";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import styles from "../views/ReportManagement.module.css"; // Reusing styles from ReportManagement

const ReviewActionModal = ({
  isOpen,
  onClose,
  actionType,
  review,
  onSubmit,
  isSubmitting,
}) => {
  const [reason, setReason] = useState("");
  const [notes, setNotes] = useState("");

  if (!review) return null;

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = actionType === "Approve" ? { notes } : { reason };

    onSubmit(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${actionType} Review`}
      size="medium"
    >
      <div className={styles.modalContent}>
        <div>
          <p>
            <strong>Interaction Type:</strong> {review.interactionType}
          </p>
          <p>
            <strong>Author:</strong> {review.author?.username || "Unknown"}
          </p>
          <p>
            <strong>Subject:</strong> {review.subject?.username || "Unknown"}
          </p>
          <p>
            <strong>Trust Score:</strong> {review.trustScore}
          </p>
          <p>
            <strong>Warmth Score:</strong> {review.warmthScore}
          </p>
          <p>
            <strong>Comments:</strong>{" "}
            {review.comments || "No comments provided"}
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          {actionType === "Approve" ? (
            <div className={styles.formGroup}>
              <label htmlFor="notes">Admin Notes (Optional):</label>
              <textarea
                id="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add any additional notes about this approval"
              />
            </div>
          ) : (
            <div className={styles.formGroup}>
              <label htmlFor="reason">Reason for Rejection:</label>
              <textarea
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Explain why this review is being rejected"
                required
              />
            </div>
          )}

          <div className={styles.modalActions}>
            <Button
              type="button"
              color="secondary"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color={actionType === "Approve" ? "primary" : "danger"}
              disabled={
                (actionType === "Reject" && !reason.trim()) || isSubmitting
              }
            >
              {isSubmitting ? "Processing..." : actionType}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ReviewActionModal;
