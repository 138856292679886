import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "hooks/useAdmin";
import { useToastContext } from "contexts/ToastContext";
import styles from "./ReviewDetail.module.css";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Button from "components/Button/Button";
import { BsArrowLeft, BsExclamationTriangle, BsPerson } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { formatDate } from "utils/dates";
import ReviewActionModal from "../../components/ReviewActionModal";

const ReviewDetail = ({ reviewId }) => {
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const {
    reviewDetails,
    fetchReviewDetail,
    approveReviewItem,
    rejectReviewItem,
    fetchReviewAdminActions,
  } = useAdmin();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [adminActions, setAdminActions] = useState([]);

  useEffect(() => {
    loadReviewDetails();
  }, [reviewId]);

  // Load admin actions when review details are loaded
  useEffect(() => {
    if (reviewDetails?._id) {
      loadAdminActions();
    }
  }, [reviewDetails]);

  const loadReviewDetails = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await fetchReviewDetail(reviewId);
    } catch (error) {
      console.error("Error loading review details:", error);
      setError("Failed to load review details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const loadAdminActions = async () => {
    try {
      const result = await fetchReviewAdminActions(reviewId);
      if (result && result.actions) {
        setAdminActions(result.actions);
      }
    } catch (error) {
      console.error("Error loading admin actions:", error);
    }
  };

  const handleBackClick = () => {
    navigate("/admin/reviews");
  };

  const handleApproveClick = () => {
    setIsApproveModalOpen(true);
  };

  const handleRejectClick = () => {
    setIsRejectModalOpen(true);
  };

  const handleApproveSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await approveReviewItem(reviewId, data);
      addToast("Review approved successfully", "success");
      loadReviewDetails();
      loadAdminActions();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
      setIsApproveModalOpen(false);
    }
  };

  const handleRejectSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      await rejectReviewItem(reviewId, data);
      addToast("Review rejected successfully", "success");
      loadReviewDetails();
      loadAdminActions();
    } catch (error) {
      addToast(`Error: ${error.message}`, "error");
    } finally {
      setIsSubmitting(false);
      setIsRejectModalOpen(false);
    }
  };

  // Helper function to get score class
  const getScoreClass = (score) => {
    if (score >= 4) return styles.highScore;
    if (score >= 2) return styles.mediumScore;
    return styles.lowScore;
  };

  // Format action type for display
  const formatActionType = (actionType) => {
    return actionType
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  if (isLoading) {
    return (
      <div className={styles.reviewDetailContainer}>
        <div className={styles.reviewDetailHeader}>
          <button className={styles.backButton} onClick={handleBackClick}>
            <BsArrowLeft /> Back to Reviews
          </button>
        </div>
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.reviewDetailContainer}>
        <div className={styles.reviewDetailHeader}>
          <button className={styles.backButton} onClick={handleBackClick}>
            <BsArrowLeft /> Back to Reviews
          </button>
        </div>
        <div className={styles.errorContainer}>
          <BsExclamationTriangle size={48} />
          <p>{error}</p>
          <Button onClick={loadReviewDetails}>Try Again</Button>
        </div>
      </div>
    );
  }

  if (!reviewDetails) {
    return (
      <div className={styles.reviewDetailContainer}>
        <div className={styles.reviewDetailHeader}>
          <button className={styles.backButton} onClick={handleBackClick}>
            <BsArrowLeft /> Back to Reviews
          </button>
        </div>
        <div className={styles.errorContainer}>
          <p>Review not found.</p>
          <Button onClick={handleBackClick}>Back to Reviews</Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.reviewDetailContainer}>
      <div className={styles.reviewDetailHeader}>
        <button className={styles.backButton} onClick={handleBackClick}>
          <BsArrowLeft /> Back to Reviews
        </button>
        <div className={styles.reviewStatus}>
          <span className={styles[`status-${reviewDetails.status}`]}>
            {reviewDetails.status}
          </span>
        </div>
      </div>

      <div className={styles.reviewDetailContent}>
        <div className={styles.reviewOverview}>
          <div className={styles.reviewInfo}>
            <h2>Review #{reviewId.substring(0, 8)}</h2>
            <div className={styles.reviewMeta}>
              <span>Created: {formatDate(reviewDetails.createdAt)}</span>
              <span>Type: {reviewDetails.interactionType}</span>
              <span className={styles[`status-${reviewDetails.status}`]}>
                {reviewDetails.status}
              </span>
            </div>
          </div>
        </div>

        {reviewDetails.status === "pending" && (
          <div className={styles.actionButtons}>
            <Button
              onClick={handleRejectClick}
              color="danger"
              disabled={isSubmitting}
            >
              Reject Review
            </Button>
            <Button
              onClick={handleApproveClick}
              color="primary"
              disabled={isSubmitting}
            >
              Approve Review
            </Button>
          </div>
        )}

        <div className={styles.reviewSection}>
          <h3>Review Details</h3>
          <div className={styles.reviewScores}>
            <div className={styles.scoreItem}>
              <div
                className={`${styles.scoreDisplay} ${getScoreClass(
                  reviewDetails.trustScore
                )}`}
              >
                {reviewDetails.trustScore}
              </div>
              <div className={styles.scoreLabel}>Trust Score</div>
            </div>
            <div className={styles.scoreItem}>
              <div
                className={`${styles.scoreDisplay} ${getScoreClass(
                  reviewDetails.warmthScore
                )}`}
              >
                {reviewDetails.warmthScore}
              </div>
              <div className={styles.scoreLabel}>Warmth Score</div>
            </div>
          </div>

          <div className={styles.detailGrid}>
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>Status</div>
              <div className={styles.detailValue}>
                <span className={styles[`status-${reviewDetails.status}`]}>
                  {reviewDetails.status}
                </span>
              </div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>Date Created</div>
              <div className={styles.detailValue}>
                {formatDate(reviewDetails.createdAt)}
              </div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>Interaction Type</div>
              <div className={styles.detailValue}>
                {reviewDetails.interactionType}
              </div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>Interaction ID</div>
              <div className={styles.detailValue}>
                {reviewDetails.interactionId}
              </div>
            </div>
          </div>

          <div className={styles.detailItem}>
            <div className={styles.detailLabel}>Comments</div>
            <div className={styles.commentBox}>
              {reviewDetails.comments || "No comments provided"}
            </div>
          </div>
        </div>

        <div className={styles.reviewSection}>
          <h3>Users Involved</h3>
          <div className={styles.detailGrid}>
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>Author</div>
              <div className={styles.detailValue}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BsPerson style={{ marginRight: "8px" }} />
                  {reviewDetails.author?.username || "Unknown"}
                  {reviewDetails.author && (
                    <Button
                      size="small"
                      className={styles.userButton}
                      onClick={() =>
                        navigate(`/admin/users/${reviewDetails.author._id}`)
                      }
                    >
                      View Profile
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.detailLabel}>Subject</div>
              <div className={styles.detailValue}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BsPerson style={{ marginRight: "8px" }} />
                  {reviewDetails.subject?.username || "Unknown"}
                  {reviewDetails.subject && (
                    <Button
                      size="small"
                      className={styles.userButton}
                      onClick={() =>
                        navigate(`/admin/users/${reviewDetails.subject._id}`)
                      }
                    >
                      View Profile
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {reviewDetails.status !== "pending" && (
          <div className={styles.reviewSection}>
            <h3>Moderation Information</h3>
            <div className={styles.detailGrid}>
              <div className={styles.detailItem}>
                <div className={styles.detailLabel}>
                  {reviewDetails.status === "approved"
                    ? "Approved At"
                    : "Rejected At"}
                </div>
                <div className={styles.detailValue}>
                  {formatDate(reviewDetails.approvedAt) || "N/A"}
                </div>
              </div>
              <div className={styles.detailItem}>
                <div className={styles.detailLabel}>
                  {reviewDetails.status === "approved"
                    ? "Approved By"
                    : "Rejected By"}
                </div>
                <div className={styles.detailValue}>
                  {reviewDetails.approvedBy?.username || "N/A"}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Admin Actions Section */}
        {adminActions.length > 0 && (
          <div className={styles.reviewSection}>
            <h3>Admin Actions</h3>
            <div className={styles.adminActionsList}>
              {adminActions.map((action, index) => (
                <div key={index} className={styles.adminActionItem}>
                  <div className={styles.adminActionHeader}>
                    <span className={styles.actionType}>
                      {formatActionType(action.actionType)}
                    </span>
                    <span className={styles.actionDate}>
                      {formatDate(action.timestamp)}
                    </span>
                  </div>
                  <div className={styles.adminActionDetails}>
                    <span className={styles.adminBy}>
                      By: {action.moderator?.username || "Unknown"}
                    </span>
                    <p className={styles.adminNote}>{action.reason}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Modals for review actions */}
      <ReviewActionModal
        isOpen={isApproveModalOpen}
        onClose={() => setIsApproveModalOpen(false)}
        actionType="Approve"
        review={reviewDetails}
        onSubmit={handleApproveSubmit}
        isSubmitting={isSubmitting}
      />

      <ReviewActionModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        actionType="Reject"
        review={reviewDetails}
        onSubmit={handleRejectSubmit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ReviewDetail;
